<template>
  <div
    class="modal fade bd-example-modal-lg"
    id="reportSummarModal"
    tabindex="-1"
    role="dialog"
    ref="modalRef"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="d-flex">
          <h4 class="col-md-12 mt-4">Transfer Plan Summary Report</h4>
          <button
            type="button"
            class="close text-right close-button"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <hr />
          <div class="report" v-html="report"></div>

          <div class="no-results text-center" v-if="loading">
            <div class="loader"></div>
          </div>
        </div>
        <div class="text-center modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-close"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportSummaryModal",
  props: {
    report: {
      type: [Object, String],
      required: false,
    },
  },

  data() {
    return {
      loading: false,
    };
  },
});
</script>
<style>
.error-message {
  color: red;
}
.text-center {
  text-align: center !important;
}
.btn-close {
  color: black;
}
.report {
  font-size: 14px;
}
.pre-wrap {
  white-space: pre-wrap;
}
.close-button span {
  font-size: 1.5rem; 
}
</style>
