<template>
  <tr class="thead-gray">
    <th scope="row" colspan="4">{{ term && term.name }}</th>
  </tr>
  <tr>
    <th>Course name</th>
    <th>Credit</th>
    <th colspan="2" v-if="isStartingSchool">Equivalency</th>
    <th v-else colspan="2"></th>
  </tr>
  <tr v-for="item in term.plan_items">
    <td class="name-cell">
      {{ item && item.requirement ? item.requirement.code : item.code }}
      {{ item && item.requirement ? item.requirement.name : item.name }}
    </td>
    <td class="p-2">
      {{ item.credits ? item.credits : item.minimum_credits }}
    </td>
    <td class="eq-cell">
      <p v-if="isStartingSchool">
        {{ item.equivalency ? item.equivalency : "" }}
      </p>
    </td>
    <td>
      <svg
        data-toggle="modal"
        :data-target="'#' + item.id"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        class="bi bi-info-circle"
        viewBox="0 0 16 16"
      >
        <path
          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
        />
        <path
          d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
        />
      </svg>
      <ReqModal
        :course="item"
        :schoolName="school.name"
        :programName="program.name"
        :destProgramName="destProgramName"
      ></ReqModal>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ReqModal from "./RequirementModal.vue";

export default defineComponent({
  name: "TermTable",
  props: {
    term: {
      type: Object,
      required: true,
    },
    isStartingSchool: {
      type: Boolean,
      required: true,
    },
    destProgramName: {
      type: String,
      required: false,
    },
    school: {
      type: Object,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {},
  components: { ReqModal },
});
</script>
