<template>
  <div class="ube-autocomplete-search">
    <!-- Search Bar -->
    <div class="search-bar">
      <span class="icon">
        <i class="fa-solid fa-magnifying-glass"></i>
      </span>
      <input type="number" :min="min" :max="max" :value="modelValue" @input="updateValue" />
      <span class="clear-icon"> </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: "NumericInput",
  props: {
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: Number,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const updateValue = (event: Event) => {
      const target = event.target as HTMLInputElement;
      const numValue = Number(target.value);
      if (!isNaN(numValue) && numValue >= props.min && numValue <= props.max) {
        emit('update:modelValue', numValue);
      }
    };

    return { updateValue };
  }
});
</script>

<style>
.disabledOption {
  color: darkgray;
  cursor: default !important;
}
</style>