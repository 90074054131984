<template>
  <table>
    <thead>
      <tr>
        <th class="thead-arizonablue" scope="col" colspan="4" v-if="school">
          <span class="mr-2">{{ school.name }}</span>
          -
          <span>{{ program && program.name }}</span>
        </th>
        <th class="thead-arizonablue" scope="col" colspan="4" v-else>
          School Name
        </th>
      </tr>
    </thead>
    <tbody class="table-white">
      <TermTable
        v-for="term in planTerm"
        :isStartingSchool="isStartingSchool"
        :term="term"
        :program="program"
        :destProgramName="destProgramName"
        :school="school"
      >
      </TermTable>
    </tbody>
  </table>
</template>

<script lang="ts">
import { program } from "@babel/types";
import { defineComponent } from "vue";
import TermTable from "./TermTable.vue";

export default defineComponent({
  name: "ReportTable",

  props: {
    school: {
      type: Object,
      required: true,
    },
    program: {
      type: Object,
      required: true,
    },
    destProgramName: {
      type: String,
      required: false,
    },
    planTerm: {
      type: Object,
      required: false,
    },
    isStartingSchool: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {},
  components: { TermTable },
});
</script>
