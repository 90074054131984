<template>
  <div class="home-view">
    <!-- Hero -->
    <div class="hero">
      <div class="hero-content">
        <h1>Making transfer credits count</h1>
        <router-link to="/transfer" class="btn btn-lg btn-red mx-3"
          >Transfer Evaluation</router-link
        >
        <router-link to="/TwoYeartoFourYear" class="btn btn-lg btn-blue"
          >2 Year to 4 Year Plan</router-link
        >
      </div>
    </div>

    <!-- Section -->
    <div class="container-fluid">
      <div class="row information">
        <h2>How it works</h2>
        <div class="col-md-4">
          <img class="icon" src="../assets/university.png" alt="university" />
          <p>Select your community college</p>
        </div>
        <div class="col-md-4">
          <img class="icon" src="../assets/graduation.png" alt="graduatoin" />
          <p>
            Select courses completed or in progress at your community college
          </p>
        </div>
        <div class="col-md-4">
          <img class="icon" src="../assets/program.png" alt="program" />
          <p>Select the intended program/major at the University of Arizona</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
});
</script>
