<template>
  <header id="region_header_ua" class="l-arizona-header bg-red">
    <router-link class="logo" to="/">
      <div>
        <img alt="Vue logo" src="./assets/Arizona_Wildcats_logo.svg.png" />
        <span>AZTransferPortal</span>
      </div>
    </router-link>
    <nav>
      <router-link to="/">Home</router-link>
      <a v-if="!loginSuceess" data-toggle="modal" data-target="#LoginModal"
        >Login</a
      >
      <router-link v-if="loginSuceess" to="/GeneratedPlans"
        >Generated Plans</router-link
      >
      <router-link to="/" v-if="loginSuceess" @click="handleLogout"
        >Logout</router-link
      >
    </nav>
  </header>
  <div class="main-content">
    <router-view />
    <LoginModal
      modalID="LoginModal"
      :setLoginSucess="setLoginSucess"
      ref="loginModal"
    ></LoginModal>
  </div>
  <footer id="footer_site">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="text-center-sm text-center-xs text-left" id="footer_logo">
            <img src="./assets/placeholder-logo.png" />
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="text-center-sm text-center-xs text-right"
            id="footer_menu"
          >
            <ul>
              <li><router-link to="/">Home</router-link></li>
              <li>
                <a
                  href="http://highereducationanalytics.com.s3-website-us-west-2.amazonaws.com/index.html"
                  target="_blank"
                  >About us</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <hr />
        <div class="col-md-12">
          <div class="text-center" id="footer_copyright">
            The University of Arizona | Tucson, AZ USA 85721 <br />
            Phone: (520) 621-2211 | Email:&nbsp;<a href="#"
              >@arizona.edu <br /></a
            >© 2016 The Arizona Board of Regents on behalf of the University of
            Arizona
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import LoginModal from "@/components/LoginModal.vue";
import store from "@/store";

export default defineComponent({
  name: "App",
  components: {
    LoginModal,
  },
  data() {
    return {
      loginSuceess: localStorage.getItem("userToken") !== null,
    };
  },

  methods: {
    setLoginSucess() {
      this.loginSuceess = true;
    },
    handleLogout() {
      this.loginSuceess = false;
      store.methods.logout();
    },
  },
  created() {
    store.methods.initUserSession();
  },
});
</script>
<style>
a {
  cursor: pointer;
}
.logo {
  color: white;
}
#egion_header_ua a:hover {
  color: white;
}
#egion_header_ua a:active {
  color: white;
}
#egion_header_ua a:visited {
  color: white;
}
</style>
