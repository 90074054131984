<template>
  <div
    class="modal fade"
    :id="modalID"
    tabindex="-1"
    role="dialog"
    :aria-labelledby="modalID"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content mx-4">
        <div class="modal-header text-center">
          <div class="row">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              ref="closebtn"
              @click="
                setSigUpSuccess(false);
                setNotAMember(false);
                ShowNewPassForm(false);
              "
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <h2 v-if="NotAMember" class="modal-title" id="exampleModalLabel">
            Sign up
          </h2>
          <h2 v-else="" class="modal-title" id="exampleModalLabel">Login</h2>
        </div>
        <div class="modal-body m-2">
          <form
            v-if="NotAMember"
            id="signup-form"
            @submit.prevent="handleSignUp"
          >
            <div class="form-group">
              <label for="name">First Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="name"
                placeholder="Enter name"
              />
            </div>
            <div class="form-group">
              <label for="lastname">Last name</label>
              <input
                type="text"
                class="form-control"
                id="lastname"
                v-model="lastName"
                placeholder="Enter last name"
              />
            </div>
            <div class="form-group">
              <label for="signUpEmail">Email address</label>
              <input
                type="email"
                class="form-control"
                id="signUpEmail"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                required
                v-model="signUpEmail"
              />
            </div>
            <div class="form-group">
              <label for="signUpPassword">Password</label>
              <p class="subtitle">
                Password must contain at least 8 characters, one uppercase
                letter, one lowercase letter, one number, and one symbol
                character
              </p>
              <input
                type="password"
                class="form-control"
                id="signUpPassword"
                placeholder="Enter password"
                v-model="signUpPassword"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}"
                title="Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one symbol character"
                required
              />
            </div>
            <div class="form-group">
              <label for="password">Confirm password</label>
              <input
                type="password"
                class="form-control"
                id="confirmPassword"
                placeholder="Confirm password"
                v-model="confirmPassword"
                required
              />
              <p id="error">{{ errorMsg }}</p>
            </div>
            <div class="row text-center">
              <button type="submit" class="btn btn-primary">Sign up</button>
              <hr />
              <p>
                Already a member?
                <a
                  id="signUpLink"
                  @click="
                    setNotAMember(false);
                    setSigUpSuccess(false);
                  "
                >
                  Login
                </a>
              </p>
            </div>
          </form>
          <form
            v-else-if="!NotAMember && !SetNewPass && !awaitingVerification"
            id="login-form"
          >
            <div class="row text-center">
              <div class="col-md-12">
                <h4 class="success-msg" v-if="SigUpSuccess">
                  Welcome to AZ Transfer Portal! Please login to continue.
                </h4>
              </div>
            </div>
            <div class="form-group">
              <label for="loginEmail">Email address</label>
              <input
                type="email"
                class="form-control"
                id="loginEmail"
                aria-describedby="emailHelp"
                placeholder="Enter email"
                v-model="loginEmail"
                required
              />
            </div>
            <div class="form-group">
              <label for="loginPassword">Password</label>
              <input
                type="password"
                class="form-control"
                id="loginPassword"
                placeholder="Enter password"
                v-model="loginPassword"
                required
              />
            </div>
            <p id="error">{{ errorMsg }}</p>
            <div class="row text-center">
              <button
                type="submit"
                class="btn btn-primary"
                @click="handleLogin"
              >
                Login
              </button>
              <hr />
              <p>
                Not a member yet?
                <a id="signUpLink" @click="setNotAMember(true)"> Sign up </a>
              </p>
            </div>
          </form>
          <form
            v-if="SetNewPass"
            id="SetPassword-form"
            @submit.prevent="completeNewPassword"
          >
            <div class="row text-center">
              <div class="col-md-12">
                <h4>Please set a new password</h4>
              </div>
            </div>
            <div class="form-group">
              <label for="newPassword">New Password</label>
              <input
                type="password"
                class="form-control"
                id="newPassword"
                placeholder="Enter new password"
                v-model="newPassword"
                required
              />
            </div>
            <div class="form-group">
              <label for="loginPassword">Confirm New Password</label>
              <input
                type="password"
                class="form-control"
                id="confirmNewPassword"
                placeholder="Confirm password"
                v-model="confirmNewPassword"
                required
              />
            </div>
            <p id="error">{{ errorMsg }}</p>
            <div class="row text-center">
              <button type="submit" class="btn btn-primary">
                Set New Password
              </button>
              <hr />
            </div>
          </form>
          <div v-if="awaitingVerification" class="verification-form">
            <div class="form-group">
              <h5>
                A verification code has been sent to your email. Please check
                your inbox and spam folder.
              </h5>
              <label for="verificationCode">Verification Code</label>
              <input
                type="text"
                class="form-control"
                id="verificationCode"
                v-model="verificationCode"
                placeholder="Enter verification code"
              />
            </div>
            <p id="error">{{ errorMsg }}</p>
            <button type="button" class="btn btn-primary" @click="verifyCode">
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import store from "@/store";
import { computed } from "vue";

const userPool = new CognitoUserPool({
  UserPoolId: "us-west-2_xlvB8IRYA",
  ClientId: "399kkbive36jlq6137rn8p0moc",
});

export default defineComponent({
  name: "LoginModal",

  setup() {
    const cognitoUser = computed(() => store.state.cognitoUser);
    const userToken = computed(() => store.state.userToken);

    return {
      cognitoUser,
      userToken,
    };
  },

  props: {
    modalID: {
      type: String,
      required: true,
    },
    setLoginSucess: {
      type: Function,
      required: true,
    },
  },
  methods: {
    setNotAMember(val: Boolean) {
      this.NotAMember = val;
      this.errorMsg = "";
    },
    setSigUpSuccess(val: Boolean) {
      this.SigUpSuccess = val;
      this.errorMsg = "";
    },
    ShowNewPassForm(val: Boolean) {
      this.SetNewPass = val;
    },
    handleSignUp(event: Event) {
      event.preventDefault();
      this.errorMsg = "";
      const email = this.signUpEmail.toString();
      const password = this.signUpPassword.toString();
      const confitmPassword = this.confirmPassword.toString();
      const name = this.name.toString();
      const lastName = this.lastName.toString();

      if (password !== confitmPassword) {
        this.errorMsg = "Passwords do not match.";
        return;
      }

      const attributeList = [
        new CognitoUserAttribute({
          Name: "email",
          Value: email,
        }),
        new CognitoUserAttribute({
          Name: "name",
          Value: name,
        }),
        new CognitoUserAttribute({
          Name: "family_name",
          Value: lastName,
        }),
      ];

      userPool.signUp(email, password, attributeList, [], (err, result) => {
        if (err) {
          console.error(err);
          this.errorMsg = err.message;
          return;
        }
        // After successful sign up, show the login form
        result && store.methods.setCognitoUser(result.user);
        this.NotAMember = false;
        this.awaitingVerification = true; // Show verification code input
      });
    },

    async handleLogin(event: Event) {
      this.errorMsg = "";
      event.preventDefault();
      const email = this.loginEmail.toString();
      const password = this.loginPassword.toString();
      this.loginWithCredentials(email, password);
    },
    async loginWithCredentials(email: string, password: string) {
      this.errorMsg = "";
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          console.log("authentication successful");
          this.setLoginSucess();
          this.setSigUpSuccess(false);
          store.methods.setCognitoUser(cognitoUser);
          store.methods.setUserToken(result.getIdToken().getJwtToken());
          store.methods.fetchUserFiles();
          localStorage.setItem("userToken", result.getIdToken().getJwtToken());
          localStorage.setItem("username", this.cognitoUser.getUsername());
          (this.$refs.closebtn as HTMLButtonElement).click();
        },
        onFailure: (err) => {
          if (err.code === "UserNotConfirmedException") {
            store.methods.setCognitoUser(cognitoUser);
            // Resend code logic
            cognitoUser.resendConfirmationCode((resendErr, resendResult) => {
              if (resendErr) {
                console.error("Error resending code:", resendErr);
                this.errorMsg =
                  resendErr.message || "Failed to resend verification code.";
                return;
              }
              console.log("Verification code resent successfully");
              this.errorMsg = "Please verify your email before logging in.";
              this.awaitingVerification = true; // Show verification code input
            });
          } else {
            console.error("authentication failed", err);
            this.errorMsg = err.message;
          }
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          console.log("New password required");
          this.SetNewPass = true;
          this.cognitoUser = cognitoUser;
        },
      });
    },
    completeNewPassword() {
      if (this.newPassword !== this.confirmNewPassword) {
        this.errorMsg = "Passwords do not match.";
        return;
      }

      // Assuming `cognitoUser` is stored after the `newPasswordRequired` callback
      this.cognitoUser.completeNewPasswordChallenge(
        this.newPassword,
        {},
        {
          onSuccess: (session: any) => {
            console.log("Password updated successfully!");
            this.setLoginSucess(); // Assuming this sets the login state in your app
            this.SetNewPass = false; // Hide the new password form
            // Close the modal
          },
          onFailure: (err: any) => {
            console.error(err);
            this.errorMsg = err.message;
          },
        }
      );
    },
    verifyCode() {
      this.errorMsg = "";
      const code = this.verificationCode.trim();
      if (!code) {
        this.errorMsg = "Please enter the verification code.";
        return;
      }

      if (this.cognitoUser) {
        this.cognitoUser.confirmRegistration(
          code,
          true,
          (err: any, result: any) => {
            if (err) {
              console.error("Error confirming registration:", err);
              this.errorMsg = err.message;
              return;
            }
            // Verification successful
            console.log("Verification successful:");
            this.awaitingVerification = false; // Hide verification code input
            this.NotAMember = false; // switch to the login form
            this.SigUpSuccess = true; // Indicate a successful sign-up
          }
        );
      } else {
        this.errorMsg =
          "There was an error verifying your account. Please try again later.";
      }
    },
    handleLogout() {
      if (this.cognitoUser != null) {
        store.methods.logout();
        console.log("User has been logged out");
      } else {
        console.log("No user is currently logged in");
      }
    },
  },

  data() {
    return {
      NotAMember: false as Boolean,
      SigUpSuccess: false as Boolean,
      signUpEmail: "" as String,
      signUpPassword: "" as String,
      loginEmail: "" as String,
      loginPassword: "" as String,
      errorMsg: "" as String,
      SetNewPass: false as Boolean,
      newPassword: "" as String,
      confirmNewPassword: "" as String,
      name: "" as String,
      lastName: "" as String,
      confirmPassword: "" as String,
      awaitingVerification: false as Boolean,
      verificationCode: "" as String,
    };
  },
});
</script>
<style>
#signUpLink {
  text-decoration: underline;
  color: blue;
}
.modal-header {
  margin: auto;
}

.btn {
  margin-top: 10px;
  min-width: 150px;
}
.close {
  margin-right: 20px;
}

.success-msg {
  color: green;
}
#error {
  color: red;
}
.subtitle {
  font-size: 12px;
  color: gray;
}
</style>
